
import BaseLayout from './BaseLayout.vue'
import { IonButton, alertController } from "@ionic/vue"
import { vedaService } from '../services/vedaService'
import { userService } from '../services/userService'

export default {
  name: 'SoftwareUpdate',
  components: {
    IonButton,
    BaseLayout
  },
  methods: {
    updateVeda: async () => {
      const alert = await alertController.create({
        header: 'Are you sure?',
        message: 'This will check for any available update and install the update automatically, if available',
        buttons: [
          {
            role: 'cancel',
            text: 'Cancel'
          }, {
            text: 'Update Software',
            handler: async () => {
              const response = await vedaService.updateVedaSoftware()
              if(response && response.data && response.data.data) {
                // window.alert(response.data.data)
                userService.openToast(response.data.data, 'light')
              }
            }
          }
        ]
      })

      return alert.present()
    }
  }
}
